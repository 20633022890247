@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: Open Sans;
  font-display: swap;
  src: url('../public/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: Open Sans;
  font-display: swap;
  src: url('../public/fonts/OpenSans-Medium.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: Open Sans;
  font-display: swap;
  src: url('../public/fonts/OpenSans-Bold.ttf') format('truetype');
}

:root {
  fontsynthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  boxsizing: 'border-box';
}

html,
:root {
  scrollbehavior: smooth;
}

body {
  font-style: normal;
  font-weight: 400;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: 'Open Sans', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace, sans-serif;
}

a {
  text-decoration: none;
  width: fit-content;
  height: fit-content;
  color: inherit;
}

label,
input,
textarea,
button,
select,
a {
  font-style: normal;
  font-weight: 400;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#root .input-container > div,
.code-container > div > div {
  font-size: 16px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 100%;
  max-width: 560px;
  background: #f7f9fc;
  border-radius: 8px;
}

#root .input-container > div input,
.code-container input {
  padding: 15px 14px;
  max-width: 560px;
}

.show-password-icon {
  position: absolute;
  right: 15px;
  top: 12px;
}

.input-container label,
.code-container label {
  line-height: 0.7;
}

.password-container .show-password-button {
  background-color: inherit;
  border: none;
  position: absolute;
  right: 15px;
  top: 20px;
  width: 25px;
  padding: 0;
  height: 25px;
  cursor: pointer;
  min-width: auto;
  box-shadow: none;
  font-size: 0;
}

.password-container .show-password-button span {
  display: none;
}

.password-container .show-password-button:hover {
  background-color: inherit;
  box-shadow: none;
}

.password-container .forget-password-input {
  border: none;
  background: white;
  text-align: left;
  font-family: 'Open Sans';
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  background-color: inherit;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  color: #0062dc;
}

.password-container {
  position: relative;
}

#root .submit-button {
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 47.6px;
  max-width: 560px;
  background: #0062dc;
  border: 2px solid #91b1dd;
  border-radius: 8px;
  font-family: 'Open Sans';
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;
}

#root .submit-button:disabled {
  background-color: rgb(0, 0, 0, 0.3);
  color: rgb(0, 0, 0, 0.4);
  border: 2px solid rgb(0, 0, 0, 0.1);
}

#root {
  overflow-y: hidden;
}

.backIcon {
  position: absolute;
  top: 50px;
  left: 730px;
  background-color: white;
  border: none;
  cursor: pointer;
}

.login-container {
  margin-bottom: 100px;
}

@media screen and (max-width: 1280px) {
  .backIcon {
    left: 50px;
    top: 25px;
  }

  .login-container {
    margin-top: 40px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 33px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 3px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
